/*----FontAwesome-------*/
@import "direction-reveal/src/styles/direction-reveal.scss";
@import "bootstrap/dist/css/bootstrap.css";
@import "aos/dist/aos.css";

// Style Scss
@import "modal";
@import "404";
@import "preview";
@import "circle";
@import "styleswitcher";
@import "slick";
@import "style";

// skin
@import "skins/yellow";
@import "skins/blue";
@import "skins/blueviolet";
@import "skins/goldenrod";
@import "skins/green";
@import "skins/magenta";
@import "skins/orange";
@import "skins/purple";
@import "skins/red";
@import "skins/yellowgreen";
